const config = {
  API_URL: process.env.REACT_APP_API_URL,
  AUTH_ENABLED: process.env.REACT_APP_AUTH_ENABLED,
  AUTH_BASE_URL: process.env.REACT_APP_AUTH_BASE_URL,
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
  AUTH_REDIRECT: process.env.REACT_APP_AUTH_REDIRECT,
  AUTH_IDENTITY_PROVIDER: process.env.REACT_APP_AUTH_IDENTITY_PROVIDER,
  AUTH_BASIC_KEY: process.env.REACT_APP_AUTH_BASIC_KEY,
}

export default config
