import React, { useState } from "react"
import { ReactComponent as Hamburger } from "../assets/hamburger.svg"
import { ReactComponent as Close } from "../assets/close.svg"
import { useAuthContext } from "../hooks/useAuthContext"
import Link from "./Link"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { user, logout } = useAuthContext()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const isUserLoggedIn = !!user

  return (
    <header className="fixed top-0 z-50 w-full bg-white py-4 lg:py-6">
      <div className="container mx-auto flex items-center justify-between">
        <div>
          <Link
            primary
            to="/profile"
            className="pl-1 text-sm font-bold lg:text-xl">
            {isUserLoggedIn && `Ciao ${user?.email}!`}
          </Link>
          {!isUserLoggedIn && (
            <Link primary to="/" className="text-sm font-bold lg:text-xl">
              <img src="logo.png" alt="logo" className="w-20" />
            </Link>
          )}
        </div>
        <button
          onClick={toggleMenu}
          className="text-blue hover:text-blue-light focus:text-blue-light focus:outline-none lg:hidden">
          {isOpen ? <Close /> : <Hamburger />}
        </button>
        {/* Desktop menu */}
        <nav className="hidden lg:flex lg:w-auto lg:items-center">
          <ul className="text-2xl lg:flex lg:space-x-4">
            <li>
              <Link
                primary
                to="/"
                className="font-bold text-blue hover:text-blue-light">
                Home
              </Link>
            </li>
            <li>
              <Link
                primary
                to="/contact"
                className="ml-6 font-bold text-blue hover:text-blue-light">
                Contattami
              </Link>
            </li>
            <li>
              <Link
                primary
                to="/chat"
                className="ml-6 font-bold text-blue hover:text-blue-light">
                Chat
              </Link>
            </li>
            {/* {isUserLoggedIn && (
              <li>
                <Link
                  primary
                  to="/products"
                  className="ml-6 font-bold text-blue hover:text-blue-light">
                  Products
                </Link>
              </li>
            )} */}
            {!isUserLoggedIn && (
              <>
                <li>
                  <Link
                    primary
                    to="/login"
                    className="ml-6 font-bold text-blue hover:text-blue-light">
                    Accedi
                  </Link>
                </li>
              </>
            )}
            {isUserLoggedIn && (
              <li>
                <Link
                  primary
                  to="/"
                  onClick={logout}
                  className="ml-6 font-bold text-blue hover:text-blue-light">
                  Esci
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <div className={`bg-white ${isOpen ? "block" : "hidden"} lg:hidden`}>
        {/* Mobile menu */}
        <nav className="px-2 py-4">
          <Link
            primary
            to="/"
            onClick={() => setIsOpen(false)}
            className="flex py-2 font-bold text-blue">
            Home
          </Link>
          <Link
            primary
            to="/contact"
            onClick={() => setIsOpen(false)}
            className="flex py-2 font-bold text-blue">
            Contattami
          </Link>
          <Link
            primary
            to="/chat"
            onClick={() => setIsOpen(false)}
            className="flex py-2 font-bold text-blue">
            Chat
          </Link>
          {/* {isUserLoggedIn && (
            <Link
              primary
              to="/products"
              onClick={() => setIsOpen(false)}
              className="flex py-2 font-bold text-blue">
              Products
            </Link>
          )} */}
          {!isUserLoggedIn && (
            <>
              <Link
                primary
                to="/login"
                onClick={() => setIsOpen(false)}
                className="flex py-2 font-bold text-blue">
                Accedi
              </Link>
            </>
          )}
          {isUserLoggedIn && (
            <Link
              primary
              to="/"
              onClick={logout}
              className="flex py-2 font-bold text-blue">
              Esci
            </Link>
          )}
        </nav>
      </div>
    </header>
  )
}

export default Header
