const ProfilePage = () => {
  return (
    <main className="container mx-auto">
      <h1 className="my-6 inline-block text-3xl font-bold leading-tight text-black md:my-10 md:text-4xl lg:text-5xl">
        Profilo
      </h1>
    </main>
  )
}

export default ProfilePage
