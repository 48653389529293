import axios from "axios"
import config from "../config"

const headers = {
  "Content-Type": "application/json",
}

const authUrl = (siteUrl) => {
  return `${config.AUTH_BASE_URL}/login?response_type=code&client_id=${config.AUTH_CLIENT_ID}&redirect_uri=${siteUrl}${config.AUTH_REDIRECT}&scope=openid+profile+aws.cognito.signin.user.admin`
}

const fetchAccess = (code) => {
  return axios.post(
    config.API_URL + "/api/v1/auth/login",
    { code },
    {
      headers,
    }
  )
}

const revokeAccess = (refreshToken) => {
  return axios.post(
    config.API_URL + "/api/v1/auth/logout",
    { refreshToken },
    {
      headers,
    }
  )
}

export { authUrl, fetchAccess, revokeAccess }
