import { useState } from "react"
import * as authService from "../services/authentication"
import { useNavigate } from "react-router-dom"
import * as localStorageService from "../services/localStorage"

function useLogin() {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const login = (username, password) => {
    setIsLoading(true)
    setError(null)

    authService
      .login(username, password)
      .then(({ data }) => {
        // save user in local storage
        localStorageService.setUserLocalStorage(
          data.user.tokenId,
          data.user.refreshToken,
          data.user.email
        )
        setIsLoading(false)
        // navigate to home page
        navigate("/")
      })
      .catch((error) => {
        console.error("error: ", error)
        setIsLoading(false)
        setError(
          error?.response && error?.response?.data
            ? error?.response?.data?.error
            : "Something went wrong"
        )
      })
  }

  return { login, isLoading, error }
}

export default useLogin
