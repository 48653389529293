import className from "classnames"
import { Link as LinkRouter } from "react-router-dom"

const Button = ({
  children,
  primary,
  secondary,
  outline,
  rounded,
  to,
  ...rest
}) => {
  const classes = className(
    rest.className,
    "flex items-center py-2.5 px-4 border font-bold tracking-wider",
    {
      "border-orange bg-orange text-white duration-300 hover:bg-orange-dark hover:text-white hover:border-orange-dark":
        primary,
      "border-blue text-blue bg-white transition duration-300 hover:bg-orange-dark hover:text-white":
        secondary,
      "rounded-full": rounded,
      "bg-white": outline,
      "text-orange": outline && primary,
      "text-gray-600": outline && secondary,
    }
  )

  return to ? (
    <LinkRouter
      to={to}
      {...rest}
      className={classes}
      style={{ display: "inline-block" }}>
      {children}
    </LinkRouter>
  ) : (
    <button {...rest} className={classes}>
      {children}
    </button>
  )
}

Button.propTypes = {
  checkVariationValue: ({ primary, secondary, success, danger }) => {
    const count =
      Number(!!primary) +
      Number(!!secondary) +
      Number(!!success) +
      Number(!!danger)

    if (count > 1) {
      return new Error(
        "Only one of primary, secondary, success, warning, danger can be true"
      )
    }
  },
}

export default Button
