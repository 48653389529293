import { useState } from 'react'
import * as authService from '../services/authentication'
import { useNavigate } from 'react-router-dom'

function useForgotPassword() {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const forgotPassword = (email) => {
    setIsLoading(true)
    setError(null)

    authService
      .forgotPassword(email)
      .then((response) => {
        setIsLoading(false)
        navigate('/')
      })
      .catch((error) => {
        console.error('error: ', error)
        setIsLoading(false)
        setError(
          error?.response &&
            error?.response?.data &&
            error?.response?.data?.error
            ? error?.response?.data?.error
            : 'Something went wrong'
        )
      })
  }

  return { forgotPassword, isLoading, error }
}

export default useForgotPassword
