import classNames from "classnames"
import { Link as LinkRouter, useLocation } from "react-router-dom"

const Link = ({
  primary,
  secondary,
  to,
  children,
  activeClassName,
  ...rest
}) => {
  const location = useLocation()
  const classes = classNames(
    rest.className,
    "inline-block",
    { "tracking-wider hover:underline hover:text-orange": primary },
    { "text-blue hover:underline": secondary },
    location.pathname === to && activeClassName
  )

  return (
    <LinkRouter {...rest} to={to} className={classes}>
      {children}
    </LinkRouter>
  )
}

Link.propTypes = {
  checkVariationValue: ({ primary, secondary }) => {
    const count = Number(!!primary) + Number(!!secondary)

    if (count > 1) {
      return new Error("Only one of primary, secondary can be true")
    }
  },
}

export default Link
