import axios from "axios"
import config from "../config"

const headers = {
  headers: {
    Authorization: `Basic ${config.AUTH_BASIC_KEY}`,
  },
}

const products = () => {
  return axios.get(config.API_URL + "/api/v1/products")
}

const saveProduct = (code, name, colour, cost) => {
  const payload = {
    code,
    name,
    description: colour,
    price: cost,
  }
  return axios.post(config.API_URL + "/api/v1/products", payload, headers)
}

const deleteProduct = (id) => {
  return axios.delete(config.API_URL + `/api/v1/products/${id}`, headers)
}

export { products, saveProduct, deleteProduct }
