import { useState } from "react"
import * as chatbotService from "../services/chatGPT"
import { useAuthContext } from "./useAuthContext"

function useChatbot() {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuthContext()
  const userName = user ? ` ${user?.email}` : ``

  const [data, setData] = useState([
    {
      role: "system",
      content: `Ciao${userName}, sono il tuo assistente virtuale di prodotti cosmetici. Come posso aiutarti?`,
      timestamp: new Date(),
    },
  ])

  const chat = async (messages) => {
    setIsLoading(true)
    setError(null)

    const newMessage = [
      ...data,
      { role: "user", content: messages, timestamp: new Date() },
    ]
    setData(newMessage)

    if (!user) {
      setData([
        ...newMessage,
        {
          role: "website",
          content: "Please Register",
          timestamp: new Date(),
        },
      ])
    } else {
      chatbotService
        .chatbot(newMessage)
        .then((response) => {
          setIsLoading(false)
          setData([
            ...newMessage,
            {
              ...response?.data?.data?.choices[0]?.message,
              timestamp: new Date(),
            },
          ])
        })
        .catch((error) => {
          setIsLoading(false)
          setError(error)
        })
    }
  }

  return { chat, isLoading, data, error }
}

export default useChatbot
