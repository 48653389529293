import Chatbot from '../../chatAI/Chatbot'
import useChatbot from '../../hooks/useChatbot'

const ChatPage = () => {
  const { chat, isLoading, data } = useChatbot()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formatData = new FormData(e.target)
    const userMessage = formatData.get('message')
    if (userMessage) {
      await chat(userMessage)
    }
  }

  return (
    <main className="container mx-auto flex flex-col items-center">
      <h1 className="mx-2 my-6 inline-block text-3xl font-bold leading-tight md:my-10 md:text-4xl lg:text-5xl">
        Chatta con il tuo assistante virtuale
      </h1>
      <Chatbot
        messages={data}
        onSubmit={handleSubmit}
        placeholder="Scrivi qui il tuo messaggio..."
        isLoading={isLoading}
      />
    </main>
  )
}

export default ChatPage
