import Button from "../components/Button"
import Link from "../components/Link"
import { useEffect } from "react"

const HomePage = () => {
  useEffect(() => {
    // Callback for IntersectionObserver
    const callback = function (entries) {
      entries.forEach((entry) => {
        // Is the element in the viewport?
        if (entry.isIntersecting) {
          // Add the fadeIn class:
          entry.target.classList.add("motion-safe:animate-fadeIn")
        } else {
          // Otherwise remove the fadein class
          entry.target.classList.remove("motion-safe:animate-fadeIn")
        }
      })
    }

    // Get all the elements you want to show on scroll
    const targets = document.querySelectorAll(".js-show-on-scroll")

    // Set up a new observer
    const observer = new IntersectionObserver(callback)

    // Loop through each of the target
    targets.forEach(function (target) {
      // Hide the element
      target.classList.add("opacity-0")

      // Add the element to the watcher
      observer.observe(target)
    })
  }, [])

  const handleClick = () => {
    // Scroll down to the next section
    const section = document.querySelector("#who-i-am")
    section.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <main>
      <section id="hero-banner">
        <div className="m-auto flex h-screen w-4/5 flex-col items-center justify-evenly px-4 py-4 text-center md:w-2/3 md:px-10 md:py-20">
          <h1 className="inline-block text-3xl font-bold tracking-widest text-orange md:pt-10 md:text-8xl">
            Lavora da casa con Rosa
          </h1>
          <div className="text-2xl">
            <p>
              Sei una mamma? Una studentessa? O semplicemente vorresti
              un'entrata extra?
            </p>
            <p>Sei sul sito giusto!</p>
          </div>
          <Button primary rounded onClick={handleClick} className="my-5">
            Dimmi di più
          </Button>
        </div>
      </section>
      <section id="who-i-am" className="js-show-on-scroll">
        <div className="m-auto flex flex-col items-center justify-evenly text-center md:h-screen md:flex-row">
          <article className="who-i-am__card">
            <h2 className="my-4 text-2xl font-bold tracking-widest md:text-3xl">
              Chi sono
            </h2>
            <p>
              Ciao sono Rosa Daniello, sono Lucana & lavoro esclusivamente da
              casa con il mio smartphone da circa 8 anni!
            </p>
            <p>
              Ogni giorno offro la mia stessa possibilità a tantissime mamme e
              studentesse che comodamente guadagnano qualche soldino
              semplicemente dal loro smartphone dedicando il tempo che possono
              dedicare!
            </p>
            <p>
              Sentire che hanno pagato con questo lavoro un bollettino, la spesa
              o semplicemente uno sfizio oppure che si sono prese la patente o
              semplicemente hanno fatto in autonomia un sabato sera senza
              chiedere i soldini ai propri genitori, per me è motivo di grande
              orgoglio!!!
            </p>
            <p>
              Provare non costa nulla!
              <br />
              Nessun investimento
              <br />
              Nessun obbligo
              <br />
              Nessun vincolo
              <br />
            </p>
            <p>Proviamoci insieme, ti aspetto 📩📲</p>
          </article>
          <article className="who-i-am__card">
            <h2 className="my-4 text-2xl font-bold tracking-widest md:text-3xl">
              Guarda la mia intervista
            </h2>
            <video className="who-i-am__video" controls>
              <source
                src="https://lavoraconrosavideo.s3.ap-southeast-2.amazonaws.com/social.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </article>
        </div>
      </section>
      <section id="photos" className="js-show-on-scroll">
        <div className="photos__gallery">
          <div className="photos__container">
            <img src="photo1.jpeg" alt="" id="photos__1" />
            <img src="photo2.jpeg" alt="" id="photos__2" />
            <img src="photo3.jpeg" alt="" id="photos__3" />
            <img src="photo4.jpeg" alt="" id="photos__4" />
          </div>
        </div>
      </section>
      <section id="social-selling" className="js-show-on-scroll">
        <div className="m-auto flex flex-col items-center justify-evenly px-4 text-center md:h-screen md:flex-row">
          <article className="social-selling__desc">
            <h3 className="text-lg font-bold tracking-widest text-orange md:text-xl">
              Cos'è il Social Selling
            </h3>
            <p className="mb-4 mt-2">
              Il Social Selling è un nuovo tipo di vendita in cui gli addetti
              alle vendite utilizzano i social per interagire direttamente con i
              loro potenziali clienti, rispondere alle loro domande e pubblicare
              contenuti sino a quando il potenziale cliente e pronto
              all'acquisto.
            </p>

            <h3 className="mt-10 text-lg font-bold tracking-widest text-orange md:text-xl">
              Come funziona?
            </h3>
            <p className="mb-4 mt-2">
              Oltre ad interagire con le persone intorno a noi, che quindi
              possono essere interessate ai prodotti ad uso quotidiano che
              proponiamo, utilizziamo anche i social media, tipo anche lo "STATO
              DI WHATSAPP", FACEBOOK, INSTAGRAM, TIK TOK per interagire
              direttamente con chi ci segue.
            </p>
          </article>
          <div className="social-selling__bgimage"></div>
        </div>
      </section>
      <section id="contacts" className="js-show-on-scroll">
        <div className="m-auto flex h-screen w-full flex-col justify-between px-4 pb-10 md:w-4/5 md:pb-20">
          <div></div>
          <div className="pl-4 text-left">
            <h3 className="text-xl font-bold tracking-widest md:text-2xl">
              Vuoi saperne di più?
            </h3>
            <Button primary rounded className="my-5" to="/contact">
              Contattami
            </Button>
          </div>
          <div className="pl-4 text-left">
            <h3 className="text-xl font-bold tracking-widest md:text-2xl">
              Vuoi Attivare un tuo codice sconto sui prodotti YR ?
            </h3>
            <ul className="contacts__sconti">
              <li>Ordine minimo 35€</li>
              <li>10% di Sconto</li>
              <li>Spedizione Gratuita</li>
              <li>Un Regalo nel pacco</li>
              <li>
                Lo utilizzi quando vuoi e per qualsiasi cosa ci sarò io a
                consigliarti
              </li>
            </ul>

            <div>Clicca il link e inizia il tuo shopping 🛍️⬇️</div>

            <Link
              primary
              to="https://www.yves-rocher.it?ba=249315"
              className="font-bold underline">
              https://www.yves-rocher.it?ba=249315
            </Link>
          </div>
        </div>
      </section>
    </main>
  )
}

export default HomePage
