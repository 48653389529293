import React, { useEffect, useRef } from "react"
import InputForm from "../components/InputForm"
import Loading from "./Loading"
import Link from "../components/Link"
import { useAuthContext } from "../hooks/useAuthContext"

const Chatbot = ({ messages, onSubmit, placeholder, isLoading }) => {
  const chatRef = useRef(null)
  const { user } = useAuthContext()

  useEffect(() => {
    if (chatRef?.current) {
      chatRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [messages])

  const messageContent = (message) => {
    if (message.role === "bot") {
      return (
        <img
          src={message.content}
          alt=""
          className="h-48 w-48 md:h-60 md:w-60"
        />
      )
    } else if (message.role === "website") {
      return (
        <div>
          <p>
            Registrati al sito per accedere alla chat virtuale ed essere sempre
            aggiornati sulle ultime offerte
          </p>
          <Link secondary to="/login" className="mb-2 mt-2 font-bold">
            Registrati
          </Link>
        </div>
      )
    } else {
      return message.content
    }
  }

  return (
    <>
      <div className="fixed bottom-32 left-0 top-48 w-full overflow-y-scroll p-4 md:p-8">
        {messages.map((message, index) => (
          <div
            key={index}
            ref={chatRef}
            className={`flex ${
              message.role === "user" ? "justify-end" : "justify-start"
            } mt-4`}>
            <div
              className={`max-w-xs rounded-lg ${
                message.role === "user"
                  ? "bg-orange text-white"
                  : "bg-white text-blue"
              } px-4 py-2 shadow-md`}>
              {messageContent(message)}
              <p className="text-xs text-blue-light">
                {message.timestamp.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
            </div>
          </div>
        ))}
        {isLoading && user && <Loading />}
      </div>
      <div className="fixed bottom-8 left-0 w-full">
        <InputForm onSubmit={onSubmit} placeholder={placeholder} />
      </div>
    </>
  )
}

export default Chatbot
