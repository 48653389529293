import { useState } from "react"
import * as chatGPTService from "../../services/chatGPT"
import Chatbot from "../../chatAI/Chatbot"

const ImageGenerationPage = () => {
  const [messages, setMessages] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData(e.target)
    const userMessage = data.get("message")
    if (userMessage) {
      const newMessage = [
        ...messages,
        { role: "user", content: userMessage, timestamp: new Date() },
      ]
      setMessages(newMessage)

      await chatGPTService
        .imageGeneration(newMessage)
        .then((response) => {
          console.log("response: ", response?.data?.data[0])
          setMessages([
            ...newMessage,
            {
              role: "bot",
              content: response?.data?.data[0]?.url,
              timestamp: new Date(),
            },
          ])
        })
        .catch((error) => {
          console.error("error: ", error)
        })
    }
  }

  return (
    <main className="container mx-auto flex flex-col items-center">
      <h1 className="mx-2 my-6 inline-block text-3xl font-bold leading-tight text-black md:mx-0 md:my-10 md:text-4xl lg:text-5xl">
        Image Generation
      </h1>
      <Chatbot
        messages={messages}
        onSubmit={handleSubmit}
        placeholder="Create an images from scratch based on a text prompt"
      />
    </main>
  )
}

export default ImageGenerationPage
