import React, { useState } from "react"
import Panel from "../../components/Panel"
import Button from "../../components/Button"
import useForgotPassword from "../../hooks/useForgotPassword"
import { AiOutlineLoading3Quarters } from "react-icons/ai"

function ForgotPassword() {
  const [username, setUsername] = useState("")
  const [hasError, setHasError] = useState(false)
  const { forgotPassword, isLoading, error } = useForgotPassword()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!username) {
      setHasError(true)
      return
    }

    await forgotPassword(username)
  }

  return (
    <main className="bg-slate-100 py-10">
      <Panel className="m-auto max-w-xl">
        <form
          onSubmit={handleSubmit}
          className="flex  flex-col items-start px-5">
          <h1 className="mb-6 text-xl font-bold">Recupera la tua password</h1>
          <label className="mb-2 text-blue" htmlFor="username">
            Inserisci l'indirizzo email indicato in fase di registrazione
          </label>
          <input
            className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-blue shadow focus:outline-none"
            id="username"
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          {hasError && !username && (
            <p className="mt-1 text-xs italic text-error">
              Please enter a username.
            </p>
          )}

          {error && (
            <div className="text-md mt-3 italic text-error">{error}</div>
          )}
          <Button
            type="submit"
            disabled={isLoading}
            primary
            rounded
            className="mt-6">
            {!isLoading ? (
              "Continua"
            ) : (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin" />
            )}
          </Button>
        </form>
      </Panel>
    </main>
  )
}

export default ForgotPassword
