import React from "react"
import {
  BsFacebook,
  BsTiktok,
  BsInstagram,
  BsWhatsapp,
  BsGoogle,
} from "react-icons/bs"
import Link from "./Link"

const Footer = () => {
  return (
    <footer className="bg-white-floral pb-2 pt-8">
      <div className="container m-auto px-4 md:px-0">
        <div className="md:flex">
          <div className="md:w-1/3">
            <h3 className="mb-4 text-lg font-bold text-blue">
              Seguimi sui social
            </h3>
            <ul className="flex">
              <li>
                <Link
                  primary
                  to="https://www.facebook.com/rosa.daniello"
                  className="mr-4"
                  target="_blank">
                  <BsFacebook className="h-10 w-10" />
                </Link>
              </li>
              <li>
                <Link
                  primary
                  to="https://www.tiktok.com/@rosadaniello84"
                  className="mr-4"
                  target="_blank">
                  <BsTiktok className="h-10 w-10" />
                </Link>
              </li>
              <li>
                <Link
                  primary
                  to="https://www.instagram.com/rosa_responsabileyr"
                  target="_blank">
                  <BsInstagram className="h-10 w-10" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="mt-8 md:ml-auto md:mt-0 md:w-1/3 md:text-center">
            <h3 className="mb-4 text-lg font-bold text-blue">
              Compila il modulo Google
            </h3>
            <ul>
              <li>
                <Link
                  primary
                  to="https://docs.google.com/forms/d/1bD2NBNhucfXKR9GC1UoAgVJql9zIvupjfGLYk1qtUYw/edit"
                  target="_blank"
                  className="mr-4">
                  <BsGoogle className="h-10 w-10" />
                </Link>
              </li>
              <h3 className="mb-4 text-lg font-bold text-blue">
                o contattami su WhatsApp
              </h3>
              <li>
                <Link
                  primary
                  to="https://wa.me/393420616223?text=Ciao%2C+vorrei+sapere+di+pi%C3%B9+sul+tuo+lavoro+da+casa"
                  target="_blank">
                  <BsWhatsapp className="h-10 w-10" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="mt-8 md:mt-0 md:w-1/3 md:text-right">
            <h3 className="mb-4 text-lg font-bold text-blue">
              Informazioni sul sito
            </h3>
            <ul className="flex flex-col leading-loose text-blue md:justify-end">
              <li>
                <Link primary to="/about" activeClassName="underline">
                  Termini e condizioni
                </Link>
              </li>
              <li>
                <Link primary to="/about" activeClassName="underline">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="border-gray-700 my-4" />
      <div className="text-center text-sm">
        <p>© 2023 Rosa. Developed by @Dom3S. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
