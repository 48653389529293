import axios from "axios"
import { useAuthContext } from "../hooks/useAuthContext"

const AuthConfigContext = ({ children }) => {
  const { user } = useAuthContext()

  axios.interceptors.request.use(
    function (config) {
      if (user) {
        config.headers.Authorization = `Bearer ${user?.tokenId}`
      }

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      return Promise.reject(error)
    }
  )
  return children
}

export default AuthConfigContext
