import { Navigate } from "react-router"
import config from "../config"
import { useAuthContext } from "../hooks/useAuthContext"

export const PrivateRoute = ({ children }) => {
  const { user } = useAuthContext()

  const isAuthenticated =
    (config.AUTH_ENABLED && !!user) || !config.AUTH_ENABLED

  if (isAuthenticated) {
    return children
  }

  return <Navigate to="/login" />
}
