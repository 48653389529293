import Accordion from "../components/Accordion"

const AboutPage = () => {
  const items = [
    {
      id: "32214",
      label: "Termini e condizioni",
      content: `
        <h2>1. Accettazione dei Termini e condizioni</h2>

        <p>Accedendo al nostro sito web, dichiarate di accettare i presenti Termini e condizioni e la nostra Privacy Policy.</p>

        <h2>2. Proprietà intellettuale</h2>

        <p>Tutti i contenuti presenti sul nostro sito web sono di proprietà esclusiva di Lavora con Rosa e sono protetti dalle leggi sulla proprietà intellettuale.</p>

        <h2>3. Utilizzo del sito web</h2>

        <p>Gli utenti possono utilizzare il nostro sito web esclusivamente per i fini indicati. E' vietato utilizzare il sito web per scopi illeciti o in violazione dei presenti Termini e condizioni.</p>

        <h2>4. Responsabilità dell'utente</h2>

        <p>Gli utenti sono responsabili del contenuto che pubblicano sul nostro sito web e si impegnano a rispettare le leggi e le normative applicabili.</p>

        <h2>5. Limitazione di responsabilità</h2>

        <p>Lavora con Rosa non è responsabile per eventuali danni o perdite causate dall'utilizzo del sito web o dei contenuti presenti sul sito.</p>

        <h2>6. Modifiche ai Termini e condizioni</h2>

        <p>Ci riserviamo il diritto di modificare i presenti Termini e condizioni in qualsiasi momento. Le modifiche saranno pubblicate sul sito web e gli utenti saranno informati tramite una notifica sul sito.</p>

        <h2>7. Contatti</h2>

        <p>Se avete domande o commenti sui nostri Termini e condizioni, potete contattarci all'indirizzo email info@lavoraconrosa.com.</p>
                
      `,
    },
    {
      id: "54326",
      label: "Privacy Policy",
      content: `
        <p>La presente Privacy Policy spiega come Lavora con Rosa raccoglie, utilizza e protegge le informazioni personali degli utenti del nostro sito web.</p>

        <h2>1. Raccolta delle informazioni</h2>

        <p>Raccogliamo informazioni personali degli utenti del nostro sito web, come nome, cognome, indirizzo email, indirizzo postale e informazioni sul lavoro e la formazione professionale degli utenti.</p>

        <h2>2. Utilizzo delle informazioni</h2>

        <p>Utilizziamo le informazioni raccolte per fornire agli utenti informazioni sui nostri servizi e sulle opportunità di lavoro e formazione disponibili. Inoltre, utilizziamo le informazioni per personalizzare la navigazione degli utenti sul nostro sito web.</p>

        <h2>3. Protezione delle informazioni</h2>

        <p>Adottiamo misure di sicurezza per proteggere le informazioni personali degli utenti dallo smarrimento, dall'accesso non autorizzato, dalla modifica e dalla divulgazione.</p>

        <h2>4. Condivisione delle informazioni</h2>

        <p>Non condividiamo le informazioni personali degli utenti con terze parti senza il loro consenso esplicito.</p>

        <h2>5. Cookie</h2>

        <p>Utilizziamo i cookie per migliorare l'esperienza di navigazione degli utenti sul nostro sito web. Gli utenti possono scegliere di disabilitare i cookie nelle impostazioni del proprio browser.</p>

        <h2>6. Modifiche alla Privacy Policy</h2>

        <p>Ci riserviamo il diritto di modificare la presente Privacy Policy in qualsiasi momento. Le modifiche saranno pubblicate sul sito web e gli utenti saranno informati tramite una notifica sul sito.</p>

        <h2>7. Contatti</h2>

        <p>Se avete domande o commenti sulla nostra Privacy Policy, potete contattarci all'indirizzo email info@lavoraconrosa.com.</p>


     `,
    },
  ]

  return (
    <main className="container mx-auto px-2">
      <h1 className="my-6 inline-block text-3xl font-bold leading-tight md:my-10 md:text-4xl lg:text-5xl">
        Informazioni sul sito
      </h1>
      <Accordion items={items} />
    </main>
  )
}

export default AboutPage
