import React, { useEffect, useRef, useState } from 'react'

const Loading = () => {
  const [show, setShow] = useState(false)
  const loadingRef = useRef(null)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
      if (loadingRef?.current) {
        loadingRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [show])

  if (!show) return null

  return (
    <div className="mt-4 flex justify-start" ref={loadingRef}>
      <div className="max-w-xs rounded-lg bg-white px-4 py-2 shadow-md">
        <div className="flex h-10 items-center justify-center">
          <div className="grid gap-2">
            <div className="flex animate-pulse items-center justify-center space-x-2">
              <div className="h-2 w-2 rounded-full bg-orange"></div>
              <div className="h-2 w-2 rounded-full bg-orange"></div>
              <div className="h-2 w-2 rounded-full bg-orange"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading
