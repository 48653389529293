import React from "react"
import {
  BsFacebook,
  BsTiktok,
  BsInstagram,
  BsWhatsapp,
  BsGoogle,
} from "react-icons/bs"
import Link from "../components/Link"

const ContactPage = () => {
  return (
    <main className="container mx-auto px-2">
      <h1 className="my-6 inline-block text-3xl font-bold leading-tight md:my-10 md:text-4xl lg:text-5xl">
        Vuoi sapere di più del Social Selling? Contattami!
      </h1>
      <p className="mb-4 text-lg">
        Se hai domande, commenti o suggerimenti, contattami tramite uno dei miei
        canali social o compilando il mio modulo Google:
      </p>

      <div className="mb-8 grid grid-cols-2 gap-4">
        <div className="mb-4">
          <h2 className="mb-2 text-2xl font-bold text-orange">WhatsApp</h2>
          <p className="text-lg">
            <Link
              primary
              to="https://wa.me/393420616223?text=Ciao%2C+vorrei+sapere+di+pi%C3%B9+sul+tuo+lavoro+da+casa"
              target="_blank">
              <BsWhatsapp className="h-10 w-10" />
            </Link>
          </p>
        </div>
        <div className="mb-4">
          <h2 className="mb-2 text-2xl font-bold text-orange">Facebook</h2>
          <p className="text-lg">
            <Link
              primary
              to="https://www.facebook.com/rosa.daniello"
              className="mr-4"
              target="_blank">
              <BsFacebook className="h-10 w-10" />
            </Link>
          </p>
        </div>
        <div className="mb-4">
          <h2 className="mb-2 text-2xl font-bold text-orange">TikTok</h2>
          <p className="text-lg">
            <Link
              primary
              to="https://www.tiktok.com/@rosadaniello84"
              className="mr-4"
              target="_blank">
              <BsTiktok className="h-10 w-10" />
            </Link>
          </p>
        </div>
        <div className="mb-4">
          <h2 className="mb-2 text-2xl font-bold text-orange">Instagram</h2>
          <p className="text-lg">
            <Link
              primary
              to="https://www.instagram.com/rosa_responsabileyr"
              target="_blank">
              <BsInstagram className="h-10 w-10" />
            </Link>
          </p>
        </div>
      </div>

      <div className="mb-4">
        <h2 className="mb-2 text-2xl font-bold text-orange">
          Modulo di contatto
        </h2>
        <p>
          Se preferisci, puoi utilizzare il mio modulo di contatto per inviarmi
          un messaggio direttamente dal sito web:
        </p>
        <p className="text-lg">
          <Link
            primary
            to="https://docs.google.com/forms/d/1bD2NBNhucfXKR9GC1UoAgVJql9zIvupjfGLYk1qtUYw/edit"
            target="_blank"
            className="mr-4">
            <BsGoogle className="h-10 w-10" />
          </Link>
        </p>
      </div>

      <p className="text-lg">Ti risponderò il prima possibile.</p>
    </main>
  )
}

export default ContactPage
