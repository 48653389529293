const setUserLocalStorage = (tokenId, refreshToken, email) => {
  localStorage.setItem("tokenId", tokenId)
  localStorage.setItem("refreshToken", refreshToken)
  localStorage.setItem("email", email)
}

const getUserLocalStorage = () => {
  return {
    tokenId: localStorage.getItem("tokenId") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    email: localStorage.getItem("email") || "",
  }
}

const removeUserLocalStorage = () => {
  localStorage.removeItem("tokenId")
  localStorage.removeItem("refreshToken")
  localStorage.removeItem("email")
}

export { setUserLocalStorage, getUserLocalStorage, removeUserLocalStorage }
