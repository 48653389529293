import axios from "axios"
import config from "../config"

const signup = (name, email, password) => {
  const payload = {
    name,
    email,
    password,
    role: "user",
  }

  return axios.post(config.API_URL + "/api/v1/auth/register", payload, {
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  })
}

const login = (email, password) => {
  const payload = {
    email,
    password,
  }

  return axios.post(config.API_URL + "/api/v1/auth/login", payload, {
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  })
}

const logout = () => {
  return axios.get(
    config.API_URL + "/api/v1/auth/logout",
    {},
    {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    }
  )
}

const forgotPassword = (email) => {
  const payload = {
    email,
  }

  return axios.post(config.API_URL + "/api/v1/auth/forgotpassword", payload, {
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  })
}

const resetPassword = (token, password) => {
  const payload = {
    password,
  }

  return axios.put(
    `${config.API_URL}/api/v1/auth/resetpassword/${token}`,
    payload,
    {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    }
  )
}

export { signup, login, logout, forgotPassword, resetPassword }
