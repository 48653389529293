import config from "../config"

const isAuthWithCognito = () => {
  if (config.AUTH_IDENTITY_PROVIDER === "cognito") {
    return true
  } else {
    return false
  }
}

export { isAuthWithCognito }
