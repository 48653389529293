import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"

const CallbackPage = () => {
  const { login } = useAuthContext()
  const [searchParams] = useSearchParams()

  const code = searchParams.get("code") || ""

  useEffect(() => {
    login(code)
  }, [login, code])

  return <div>Logging in...</div>
}

export default CallbackPage
