import { createContext, useEffect, useState } from "react"
import * as localStorageService from "../services/localStorage"
import * as cognitoService from "../services/cognito"
import { useNavigate } from "react-router-dom"

const AuthContext = createContext()

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const { tokenId, refreshToken, email } =
      localStorageService.getUserLocalStorage()

    if (email) {
      setUser({
        email,
        tokenId,
        refreshToken,
      })
    }
  }, [])

  const login = (code) => {
    cognitoService
      .fetchAccess(code)
      .then(({ data }) => {
        const dataUser = data && data.data && data.data.user
        // save user in local storage
        localStorageService.setUserLocalStorage(
          dataUser.tokenId,
          dataUser.refreshToken,
          dataUser.email
        )

        setUser({
          email: dataUser.email,
          tokenId: dataUser.tokenId,
          refreshToken: dataUser.refreshToken,
        })

        // navigate to home page
        navigate("/")
      })
      .catch((err) => {
        console.log(`Error fetching access token: ${err}`)
      })
  }

  const logout = () => {
    const { refreshToken } = localStorageService.getUserLocalStorage()
    cognitoService
      .revokeAccess(refreshToken)
      .then((response) => {
        // remove user from local storage to log user out
        localStorageService.removeUserLocalStorage()
        setUser(null)
        // navigate to login page
        navigate("/")
      })
      .catch((error) => {
        console.error("error: ", error)
      })
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContextProvider }
export default AuthContext
