import axios from "axios"
import config from "../config"

const chatbot = (messages) => {
  const messagePayload = messages.map((message) => ({
    content: message.content,
    role: message.role,
  }))
  const payload = {
    messages: messagePayload,
    model: "gpt-3.5-turbo",
  }
  return axios.post(`${config.API_URL}/api/v1/chat`, payload)
}

const imageGeneration = (messages) => {
  console.log(messages[messages.length - 1])
  const payload = {
    prompt: messages[messages.length - 1].content,
    size: "256x256",
    n: 1,
  }
  return axios.post(`${config.API_URL}/api/v1/chat/image`, payload)
}

export { chatbot, imageGeneration }
