import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { PrivateRoute } from "./auth/PrivateRoute"
import AboutPage from "./pages/AboutPage"
import ChatPage from "./pages/chat/ChatPage"
import HomePage from "./pages/HomePage"
import DefaultLayout from "./pages/layouts/DefaultLayout"
import LoginPage from "./pages/login/LoginPage"
import ProfilePage from "./pages/ProfilePage"
import SignupPage from "./pages/login/SignupPage"
import { AuthContextProvider } from "./context/AuthContext"
import AuthConfigContext from "./auth/AuthConfigContext"
import ProductsPage from "./pages/ProductsPage"
import SimpleLayout from "./pages/layouts/SimpleLayout"
import ImageGenerationPage from "./pages/chat/ImageGenerationPage"
import ContactPage from "./pages/ContactPage"
import ScrollToTop from "./components/utils/ScrollToTop"
import ForgotPassword from "./pages/login/ForgotPassword"
import ResetPassword from "./pages/login/ResetPassword"
import CallbackPage from "./pages/CallbackPage"

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthContextProvider>
        <AuthConfigContext>
          <Routes>
            <Route path="/" element={<DefaultLayout />}>
              <Route index element={<HomePage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="callback" element={<CallbackPage />} />
              <Route path="signup" element={<SignupPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />
              <Route path="resetpassword/:id" element={<ResetPassword />} />
              <Route
                path="profile"
                element={
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                }
              />
              <Route index path="products" element={<ProductsPage />} />
            </Route>

            <Route path="chat" element={<SimpleLayout />}>
              <Route index element={<ChatPage />} />
            </Route>
            <Route path="image" element={<SimpleLayout />}>
              <Route index element={<ImageGenerationPage />} />
            </Route>
            <Route path="contact" element={<SimpleLayout />}>
              <Route index element={<ContactPage />} />
            </Route>
          </Routes>
        </AuthConfigContext>
      </AuthContextProvider>
    </BrowserRouter>
  )
}

export default App
