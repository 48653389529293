import { useState } from "react"
import * as authService from "../services/authentication"
import { useNavigate } from "react-router-dom"

function useSignup() {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const signup = async (name, username, password) => {
    setIsLoading(true)
    setError(null)

    authService
      .signup(name, username, password)
      .then((response) => {
        // update the user in the context
        setIsLoading(false)
        // navigate to home page
        navigate("/")
      })
      .catch((error) => {
        setIsLoading(false)
        setError(
          error?.response && error?.response?.data
            ? error?.response?.data?.error
            : "Something went wrong"
        )
      })
  }

  return { signup, isLoading, error }
}

export default useSignup
