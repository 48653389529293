import { useEffect, useState } from "react"
import Button from "../components/Button"
import Panel from "../components/Panel"
import SortableTable from "../components/SortableTable"
import * as productService from "../services/product"
import { GrFormTrash } from "react-icons/gr"
import Dropdown from "../components/Dropdown"

const ProductsPage = () => {
  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [colour, setColour] = useState("")
  const [cost, setCost] = useState("")

  const [products, setProducts] = useState([])
  const [hasError, setHasError] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    productService
      .products()
      .then((response) => {
        setProducts(response.data)
      })
      .catch((error) => {
        console.log("error: ", error)
        setError(error?.response?.data?.message || "Something went wrong")
      })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!code || !name || !cost) {
      setHasError(true)
      return
    }
    productService
      .saveProduct(code, name, colour.value, cost)
      .then((response) => {
        setProducts([...products, response.data])
        setCode("")
        setName("")
        setColour("")
        setCost("")
      })
      .catch((error) => {
        console.error("error: ", error)
        setError(error?.response?.data?.message || "Something went wrong")
      })
  }

  const handleDelete = (id) => {
    productService
      .deleteProduct(id)
      .then((response) => {
        setProducts(products.filter((product) => product._id !== id))
      })
      .catch((error) => {
        console.error("error: ", error)
        setError(error?.response?.data?.message || "Something went wrong")
      })
  }

  const config = [
    {
      label: "Code",
      render: (product) => product.code,
      sortValue: (product) => product.code,
    },
    {
      label: "Name",
      render: (product) => product.name,
      sortValue: (product) => product.name,
    },
    {
      label: "Colour",
      render: (product) => <div className={`m-2 p-3 ${product.description}`} />,
    },
    {
      label: "Price",
      render: (product) => product.price,
      sortValue: (product) => product.price,
    },
    {
      label: "Delete",
      render: (product) => (
        <button onClick={() => handleDelete(product._id)}>
          <GrFormTrash />
        </button>
      ),
    },
  ]

  const keyFn = (product) => {
    return product._id
  }

  const options = [
    { label: "Orange", value: "bg-orange-500" },
    { label: "Blue", value: "bg-blue-500" },
    { label: "Green", value: "bg-green-500" },
    { label: "Red", value: "bg-red-500" },
    { label: "Yellow", value: "bg-yellow-500" },
  ]

  const handleSelect = (option) => {
    setColour(option)
  }

  return (
    <main className="bg-slate-100">
      <div className="flex flex-col items-center justify-center pb-8">
        <h1 className="my-6 inline-block text-3xl font-bold leading-tight text-black md:my-10 md:text-4xl lg:text-5xl">
          Products
        </h1>
        <Panel className="max-w-xl px-8 py-6">
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="code"
              className="mb-2 block text-sm font-bold text-blue">
              Code
            </label>
            <input
              id="code"
              name="code"
              placeholder="ABC123"
              onChange={(event) => setCode(event.target.value)}
              value={code}
              className="w-full rounded border px-3 py-2 leading-tight text-blue shadow"
            />
            {hasError && !code && (
              <p className="mt-1 text-xs italic text-error">
                Please fill out this field.
              </p>
            )}
            <label
              className="mb-2 mt-4 block text-sm font-bold text-blue"
              htmlFor="name">
              Name
            </label>
            <input
              name="name"
              onChange={(event) => setName(event.target.value)}
              value={name}
              className="w-full rounded border px-3 py-2 leading-tight text-blue shadow"
            />
            {hasError && !name && (
              <p className="mt-1 text-xs italic text-error">
                Please fill out this field.
              </p>
            )}
            <label
              className="mb-2 mt-4 block text-sm font-bold text-blue"
              htmlFor="colour">
              Colour
            </label>
            <Dropdown
              name="colour"
              options={options}
              value={colour}
              onChange={handleSelect}
            />
            {/* <input
              name="colour"
              onChange={(event) => setColour(event.target.value)}
              value={colour}
              placeholder="bg-green-500"
              className="w-full rounded border py-2 px-3 leading-tight text-blue shadow"
            /> */}
            <label
              htmlFor="cost"
              className="mb-2 mt-4 block text-sm font-bold text-blue">
              Cost
            </label>
            <input
              name="cost"
              onChange={(event) => setCost(event.target.value)}
              value={cost}
              type="number"
              step="any"
              placeholder="1.50"
              className="w-full rounded border px-3 py-2 leading-tight text-blue shadow"
            />
            {hasError && !cost && (
              <p className="mt-1 text-xs italic text-error">
                Please fill out this field.
              </p>
            )}
            {error && <p className="mt-6 text-xs italic text-error">{error}</p>}
            <Button primary rounded type="submit" className="mt-6">
              Add Product
            </Button>
          </form>
          <div className="mt-10">
            <SortableTable data={products} config={config} keyFn={keyFn} />
          </div>
        </Panel>
      </div>
    </main>
  )
}

export default ProductsPage
