import React, { useEffect, useState } from "react"
import useLogin from "../../hooks/useLogin"
import Button from "../../components/Button"
import Link from "../../components/Link"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import * as cognitoService from "../../services/cognito"
import { isAuthWithCognito } from "../../utils/helper"
import Panel from "../../components/Panel"

function LoginPage() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [hasError, setHasError] = useState(false)
  const { login, isLoading, error } = useLogin()

  useEffect(() => {
    if (isAuthWithCognito()) {
      const authUrl = cognitoService.authUrl(
        window.location.protocol + "//" + window.location.host
      )
      window.location.replace(authUrl)
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!username || !password) {
      setHasError(true)
      return
    }

    await login(username, password)
  }

  if (isAuthWithCognito()) {
    return (
      <main className="bg-slate-100 py-10">
        <div className="m-auto max-w-xl">Loading...</div>
      </main>
    )
  }

  return (
    <main className="bg-slate-100 py-10">
      <Panel className="m-auto max-w-xl">
        <form
          onSubmit={handleSubmit}
          className="flex  flex-col items-start px-5">
          <h1 className="mb-6 text-xl font-bold">Accedi al sito</h1>
          <label className="mb-2 text-blue" htmlFor="username">
            Email
          </label>
          <input
            className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-blue shadow focus:outline-none"
            id="username"
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          {hasError && !username && (
            <p className="mt-1 text-xs italic text-error">
              Email obbligatoria.
            </p>
          )}
          <label className="mb-2 mt-4 text-blue" htmlFor="password">
            Password
          </label>
          <input
            className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-blue shadow focus:outline-none"
            id="password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {hasError && !password && (
            <p className="mt-1 text-xs italic text-error">
              Password obbligatoria.
            </p>
          )}
          {error && (
            <div className="text-md mt-3 italic text-error">{error}</div>
          )}

          <Button
            type="submit"
            disabled={isLoading}
            primary
            rounded
            className="mt-6">
            {!isLoading ? (
              "Accedi"
            ) : (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin" />
            )}
          </Button>
          <Link secondary to="/signup" className="mt-8 font-bold">
            Non hai un account? Registrati ora
          </Link>
          <Link secondary to="/forgotpassword" className="mt-4 font-bold">
            Hai dimenticato la password?
          </Link>
        </form>
      </Panel>
    </main>
  )
}

export default LoginPage
